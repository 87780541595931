import { Profile, Quote } from '@/types/common-types'
import { ProfileContext } from 'context/Profile'
import { createContext, useState, useEffect, useContext } from 'react'

interface PreferencesContext {
	productView: 'grid' | 'list'
	setView: (view: 'grid' | 'list') => void
	productRegion: Quote['productRegion'] | ''
	setRegion: (region: Quote['productRegion'] | '') => void
}

export const PreferencesContext = createContext<PreferencesContext>({
	productView: 'grid',
	setView: (view: 'list' | 'grid') => null,
	productRegion: '',
	setRegion: (region: Quote['productRegion'] | '') => null
})

interface Props {
	children: any
}

export const PreferencesProvider = ({ children }: Props) => {
	const { profile } = useContext(ProfileContext)
	const [productView, setProductView] = useState<'grid' | 'list'>('grid')
	const [productRegion, setProductRegion] = useState<Quote['productRegion'] | ''>('')

	function setView(view: 'grid' | 'list') {
		setProductView(view)
	}

	useEffect(() => {
		if (localStorage.getItem('productView')) {
			setView(localStorage.getItem('productView') as 'grid' | 'list')
		}
	}, [])

	useEffect(() => {
		if (localStorage.getItem('productRegion')) {
			setProductRegion(localStorage.getItem('productRegion') as Quote['productRegion'] | '')
		}
	}, [])

	function setRegion(region: Quote['productRegion'] | '') {
		setProductRegion(region)
	}

	useEffect(() => {
		if (profile && profile.productRegion) {
			setRegion(profile.productRegion as Quote['productRegion'] | '')
		} else if (localStorage.getItem('productRegion')) {
			setRegion(localStorage.getItem('productRegion') as Quote['productRegion'] | '')
		}
	}, [profile])

	useEffect(() => {
		localStorage.setItem('productView', productView)
	}, [productView])

	useEffect(() => {
		localStorage.setItem('productRegion', productRegion)
	}, [productRegion])

	return (
		<PreferencesContext.Provider
			value={{
				productView,
				setView,
				productRegion,
				setRegion
			}}
		>
			{children}
		</PreferencesContext.Provider>
	)
}
