import { SalesforceAccount } from '@/types/common-types'
import { ProfileContext } from 'context/Profile'
import { createContext, useContext, useEffect, useState } from 'react'

interface SetupContext {
	step: number
	setStep: (step: number) => void
	account: SalesforceAccount | null
	setAccount: (account: SalesforceAccount | null) => void
	cantLocateAccount: boolean
	setCantLocateAccount: (cantLocateAccount: boolean) => void
	contact: {
		firstName: string
		lastName: string
		title: string
		phone: string
		zipCode: string
		schoolDistrict: string
		schoolAddress: string
		email: string
		id: string
	}
	setContact: (contact: {
		firstName: string
		lastName: string
		title: string
		phone: string
		zipCode: string
		schoolDistrict: string
		schoolAddress: string
		email: string
		id: string
	}) => void
}

export const SetupContext = createContext<SetupContext>({
	step: 0,
	setStep: () => {},
	account: null,
	setAccount: () => {},
	cantLocateAccount: false,
	setCantLocateAccount: () => {},
	contact: {
		firstName: '',
		lastName: '',
		title: '',
		phone: '',
		zipCode: '',
		schoolDistrict: '',
		schoolAddress: '',
		email: '',
		id: ''
	},
	setContact: () => {}
})

interface Props {
	children: any
}

export const SetupProvider = ({ children }: Props) => {
	const { profile } = useContext(ProfileContext)

	const [step, setStep] = useState(0)
	const [account, setAccount] = useState<SalesforceAccount | null>(null)
	const [cantLocateAccount, setCantLocateAccount] = useState(false)
	const [contact, setContact] = useState({
		firstName: profile?.firstName || '',
		lastName: profile?.lastName || '',
		title: profile?.title || '',
		phone: profile?.phone || '',
		zipCode: '',
		schoolDistrict: '',
		schoolAddress: '',
		email: profile?.email || '',
		id: profile?.id || ''
	})

	useEffect(() => {
		if (profile) {
			setContact({
				firstName: profile.firstName,
				lastName: profile.lastName,
				title: profile.title,
				phone: profile.phone,
				zipCode: '',
				schoolDistrict: '',
				schoolAddress: '',
				email: profile.email,
				id: profile.id
			})
		}
	}, [profile])

	return (
		<SetupContext.Provider
			value={{
				step,
				setStep,
				account,
				setAccount,
				cantLocateAccount,
				setCantLocateAccount,
				contact,
				setContact
			}}
		>
			{children}
		</SetupContext.Provider>
	)
}
