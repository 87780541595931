import { createContext, useState, useEffect, useContext } from 'react'
import { UsersCollection } from '../clients/firebase-client'
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore'
import { Profile } from 'types/common-types'
import { AuthContext } from './Auth'
import LogRocket from 'logrocket'
interface ProfileContext {
	profile: Profile | null
	profileLoading: boolean
	profileError: string
}

export const ProfileContext = createContext<ProfileContext>({
	profile: null,
	profileLoading: true,
	profileError: ''
})

interface Props {
	children: any
}

export const ProfileProvider = ({ children }: Props) => {
	const { user } = useContext(AuthContext)
	const [profile, setProfile] = useState<Profile | null>(null)
	const [profileLoading, setProfileLoading] = useState(true)
	const [profileError, setProfileError] = useState('')

	useEffect(() => {
		async function getProfile(id: string) {
			try {
				const docRef = doc(UsersCollection, id)
				const document = await getDoc(docRef)
				if (document.exists()) {
					setProfile({ ...document.data(), id: document.id })
					setProfileLoading(false)
					setProfileError('')
					updateDoc(docRef, { lastActive: Timestamp.now() })
				} else {
					setProfileError('The profile does not exist.')
					setProfileLoading(false)
				}
			} catch (e: any) {
				console.log(e)
				setProfileLoading(false)
				setProfileError(e.message)
			}
		}
		if (user) getProfile(user.uid)
	}, [user])

	useEffect(() => {
		if (profile) {
			LogRocket.identify(profile.id, {
				name: `${profile?.firstName} ${profile?.lastName}`,
				email: profile.email,
				title: profile.title || '',
				role: profile.role || ''
			})
		}
	}, [profile])

	return (
		<ProfileContext.Provider value={{ profile, profileLoading, profileError }}>{children}</ProfileContext.Provider>
	)
}
