import { createContext, useState, useEffect } from 'react'
import { Quote } from '@/types/common-types'

interface QuoteContext {
	quote: Quote | null
	setQuote: (quote: Quote | null) => void
	editMode: boolean
	setEditMode: (truefalse: boolean) => void
}

export const QuoteContext = createContext<QuoteContext>({
	quote: null,
	setQuote: () => null,
	editMode: false,
	setEditMode: () => null
})

interface Props {
	children: any
}

export const QuoteProvider = ({ children }: Props) => {
	const [quote, setQuote] = useState<Quote | null>(null)
	const [editMode, setEditMode] = useState(false)

	// Set the quote edit mode to local storage
	useEffect(() => {
		localStorage.setItem('quoteEditMode', JSON.stringify(editMode))
	}, [editMode])

	return <QuoteContext.Provider value={{ quote, setQuote, editMode, setEditMode }}>{children}</QuoteContext.Provider>
}
