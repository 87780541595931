import { CartItem, Category, SalesforceAccount } from 'types/common-types'
const packageJson = require('../package.json')

export const accountManager = {
	email: 'dan.herbst@americanreading.com',
	firstName: 'Dan',
	lastName: 'Herbst',
	id: '0053h000003uZtJAAU',
	name: 'Dan Herbst',
	phone: '6109924150'
}

export const cartItem: CartItem = {
	id: '2kuIqKKzXVJQsbCiCLIw1L',
	name: 'Foundational Skills Toolkit Framework 2G',
	sku: 'FSTK-E4CC-2G',
	price: 150,
	quantity: 2,
	salesforceProductId: '01t3h00000213UHAAY',
	image: 'https://images.ctfassets.net/xo7k5gkhtsb4/wevkYnX4xEpcI04hgrftR/c975996fd9af5694e55e61867c9173eb/FSTK_-_2G___Thumbnail.png',
	region: 'standard'
}

export const category: Category = {
	name: 'Take Home Book Bags',
	description: 'Packs of 15',
	slug: 'take-home-book-bags',
	sys: { id: '6SQ8LNJLlBMM8iwCWzX3t3' },
	image: {
		url: 'https://images.ctfassets.net/xo7k5gkhtsb4/7swUznuvnCtYg4zYAyg3Fh/1606ad85c889b830006e4139d682e342/year-long-book-bags.png'
	},
	productsCollection: { items: [] },
	categoryGroup: 'Student Consumables',
	language: 'English'
}

export const salesforceAccount: SalesforceAccount = {
	type: 'Other',
	id: '0016S0000356dxpQAA',
	name: 'LADOE Quotes',
	ncesId: '99999999',
	address: {
		address1: '480 Norristown Road',
		city: 'Blue Bell',
		state: 'PA',
		zipCode: '19422'
	},
	territoryId: 'a0r3h000000Sx7EAAS'
}

export const releaseVersion = packageJson.version

export const ladoeVendorNumber = '310093615'
export const ladoeContractNumber = '4400023586'

export const loadingMessages = [
	'Reticulating splines...',
	'Generating witty dialogue...',
	'Swapping time and space...',
	'Spinning violently around the y-axis...',
	'Tokenizing real life...',
	'Bending the spoon...',
	'Have a good day.',
	'The architects are still drafting',
	"We're building the buildings as fast as we can",
	'Would you prefer chicken, steak, or tofu?',
	'(Pay no attention to the man behind the curtain)',
	'...and enjoy the elevator music...',
	'Please wait while the little elves draw your map',
	"Don't worry - a few bits tried to escape, but we caught them",
	'Would you like fries with that?',
	'Checking the gravitational constant in your locale...',
	'Go ahead -- hold your breath!',
	"...at least you're not on hold...",
	'Hum something loud while others stare',
	"You're not in Kansas any more, unless you're from Kansas, then you might be...",
	'The server is powered by a lemon and two electrodes.',
	"We're testing your patience",
	'As if you had any other choice',
	'Follow the white rabbit',
	"Why don't you order a sandwich?",
	'While the satellite moves into position',
	'The bits are flowing slowly today',
	"Dig on the 'X' for buried treasure... ARRR!",
	"It's still faster than you could draw it",
	'I should have had a V8 this morning.',
	'My other loading screen is much faster.',
	'Reconfoobling energymotron...',
	'(Insert quarter)',
	'Are we there yet?',
	'Just count to 10',
	"It's not you. It's me.",
	'Counting backwards from infinity',
	"Don't panic...",
	'Embiggening Prototypes',
	'Do not run! We are your friends!',
	"We're making you a cookie.",
	'Creating time-loop inversion field',
	'Spinning the wheel of fortune...',
	'Loading the enchanted bunny...',
	'Computing chance of success',
	'Looking for exact change',
	'All your web browser are belong to us',
	'All I really need is a kilobit.',
	'I feel like im supposed to be loading something. . .',
	'What do you call 8 Hobbits? A Hobbyte.',
	'Adjusting flux capacitor...',
	'Please wait until the sloth starts moving.',
	"Don't break your screen yet!",
	"I swear it's almost done.",
	"Let's take a mindfulness minute...",
	'Unicorns are at the end of this road, I promise.',
	'Listening for the sound of one hand clapping...',
	"Keeping all the 1's and removing all the 0's...",
	'Putting the icing on the cake.',
	'Cleaning off the cobwebs...',
	"Making sure all the i's have dots...",
	'We need more dilithium crystals',
	'Where did all the internets go',
	'Granting wishes...',
	'Time flies when you’re having fun.',
	'Get some coffee and come back in a minute..',
	'Spinning the hamster…',
	'99 bottles of beer on the wall..',
	'Stay awhile and listen...',
	'Be careful not to step in the git-gui',
	'You shall not pass! yet..',
	'Load it and they will come',
	'There is no spoon. Because we are not done loading it',
	'Your left thumb points to the right and your right thumb points to the left.',
	'How did you get here?',
	'Wait, do you smell something burning?',
	'Computing the secret to life, the universe, and everything.',
	'When nothing is going right, go left!!...',
	"I'm not lazy, I'm just relaxed!!",
	'Why are they called apartments if they are all stuck together?',
	'Life is Short – Talk Fast!!!!',
	'Whenever I find the key to success, someone changes the lock.',
	'I’ve got problem for your solution…..',
	'Where there’s a will, there’s a relative.',
	'Adults are just kids with money.',
	'I think I am, therefore, I am. I think.',
	'May the forks be with you',
	'Constructing additional pylons...',
	'Locating Jebediah Kerman...',
	'We are not liable for any broken screens as a result of waiting.',
	'Hello IT, have you tried turning it off and on again?',
	'If you type Google into Google you can break the internet',
	'Well, this is embarrassing.',
	'What is the airspeed velocity of an unladen swallow?',
	'Hello, IT... Have you tried forcing an unexpected reboot?',
	'The Elders of the Internet would never stand for it.',
	'Space is invisible mind dust, and stars are but wishes.',
	"Didn't know paint dried so quickly.",
	'Everything sounds the same',
	"I'm going to walk the dog",
	'Dividing by zero...',
	'If I’m not back in five minutes, just wait longer.',
	'Some days, you just can’t get rid of a bug!',
	'We’re going to need a bigger boat.',
	'Simulating traveling salesman...',
	'Entangling superstrings...',
	'Twiddling thumbs...',
	'Searching for plot device...',
	'Trying to sort in O(n)...',
	'Looking for sense of humour, please hold on.',
	'Please wait while the intern refills his coffee.',
	'A different error message? Finally, some progress!',
	'Please hold on as we reheat our coffee',
	'Kindly hold on as we convert this bug to a feature...',
	'Installing dependencies',
	'Switching to the latest JS framework...',
	'Distracted by cat gifs',
	'BRB, working on my side project',
	'@todo Insert witty loading message',
	"Let's hope it's worth the wait",
	'Aw, snap! Not..',
	'Ordering 1s and 0s...',
	'Updating dependencies...',
	"Whatever you do, don't look behind you...",
	'Please wait... Consulting the manual...',
	'Loading funny message...',
	'Waiting while Daenerys lists all her titles...',
	'Feel free to spin in your chair',
	'What the what?',
	'format C: ...',
	'Forget you saw that password I just typed into the IM ...',
	"What's under there?",
	'Go ahead, hold your breath and do an ironman plank till loading complete',
	'Bored of slow loading spinner, buy more RAM!',
	"Help, I'm trapped in a loader!",
	'What is the difference btwn a hippo and a zippo? One is really heavy, the other is a little lighter',
	'Please wait, while we purge the Decepticons for you. Yes, you can thank us later!',
	'Mining some bitcoin...',
	'Downloading more RAM..',
	'Updating to Windows Vista...',
	'Deleting System32 folder',
	'Initializing the initializer...',
	'When was the last time you dusted around here?',
	'Optimizing the optimizer...',
	'Last call for the data bus! All aboard!',
	'Running swag sticker detection...',
	"Never let a computer know you're in a hurry.",
	'A computer will do what you tell it to do, but that may be much different from what you had in mind.',
	"Some things man was never meant to know. For everything else, there's Google.",
	'Shovelling coal into the server',
	'Pushing pixels...',
	'How about this weather, eh?',
	'Everything in this universe is either a potato or not a potato',
	'The severity of your issue is always lower than you expected.',
	'Updating Updater...',
	'Downloading Downloader...',
	'Debugging Debugger...',
	'Reading Terms and Conditions for you.',
	'Digested cookies being baked again.',
	'Live long and prosper.',
	'Running with scissors...',
	'You may call me Steve.',
	'You seem like a nice person...',
	"Coffee at my place, tommorow at 10A.M. - don't be late!",
	'Work, work...',
	'Patience! This is difficult, you know...',
	'Discovering new ways of making you wait...',
	'Time flies like an arrow; fruit flies like a banana',
	'Two men walked into a bar; the third ducked...',
	'Sooooo... Have you seen my vacation photos yet?',
	"Sorry we are busy catching em' all, we're done soon",
	'TODO: Insert elevator music',
	'Still faster than Windows update',
	'Please wait while the minions do their work',
	'Grabbing extra minions',
	'Doing the heavy lifting',
	"We're working very Hard .... Really",
	'Waking up the minions',
	'You are number 2843684714 in the queue',
	'Please wait while we serve other customers...',
	'Our premium plan is faster',
	'Feeding unicorns...',
	'Rupturing the subspace barrier',
	'Creating an anti-time reaction',
	'Converging tachyon pulses',
	'Bypassing control of the matter-antimatter integrator',
	'Adjusting the dilithium crystal converter assembly',
	'Reversing the shield polarity',
	'Disrupting warp fields with an inverse graviton burst',
	'Up, Up, Down, Down, Left, Right, Left, Right, B, A.',
	'Do you like my loading animation? I made it myself',
	'Whoah, look at it go!',
	"No, I'm awake. I was just resting my eyes.",
	'One mississippi, two mississippi...',
	"Don't panic... AHHHHH!",
	'Ensuring Gnomes are still short.',
	'Baking ice cream...'
]
