import { Book, Product } from '@/types/common-types'
import { createContext, useState } from 'react'

interface LouisianaContext {
	product: Product | null
	setProduct: (product: Product) => void
	book: Book | null
	setBook: (book: Book) => void
	productModalOpen: boolean
	setProductModalOpen: (truefalse: boolean) => void
	bookModalOpen: boolean
	setBookModalOpen: (truefalse: boolean) => void
}

export const LouisianaContext = createContext<LouisianaContext>({
	product: null,
	setProduct: () => null,
	book: null,
	setBook: () => null,
	productModalOpen: false,
	setProductModalOpen: () => null,
	bookModalOpen: false,
	setBookModalOpen: () => null
})

interface Props {
	children: any
}

export const LouisianaProvider = ({ children }: Props) => {
	const [product, setProduct] = useState<Product | null>(null)
	const [book, setBook] = useState<Book | null>(null)
	const [productModalOpen, setProductModalOpen] = useState(false)
	const [bookModalOpen, setBookModalOpen] = useState(false)

	return (
		<LouisianaContext.Provider
			value={{
				product,
				setProduct,
				book,
				setBook,
				productModalOpen,
				setProductModalOpen,
				bookModalOpen,
				setBookModalOpen
			}}
		>
			{children}
		</LouisianaContext.Provider>
	)
}
