import { Feedback, Profile, Quote } from '@/types/common-types'
import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth'
import { getFirestore, DocumentData, CollectionReference, collection } from 'firebase/firestore'

initializeApp({
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
})

const db = getFirestore()
const auth = getAuth()
const googleProvider = new GoogleAuthProvider()
const microsoftProvider = new OAuthProvider('microsoft.com')
microsoftProvider.setCustomParameters({
	tenant: '1a4576d7-cf6b-4bf6-8954-6ecbaeb20732'
})

const createCollection = <T = DocumentData>(collectionName: string) => {
	return collection(db, collectionName) as CollectionReference<T>
}

const QuotesCollection = createCollection<Quote>('Quotes')
const UsersCollection = createCollection<Profile>('Users')
const FeedbackCollection = createCollection<Feedback>('Feedback')

export { db, auth, googleProvider, microsoftProvider, QuotesCollection, UsersCollection, FeedbackCollection }
