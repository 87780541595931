import { createContext, useEffect, useState } from 'react'
import { CartItem } from 'types/common-types'

interface CartContext {
	cart: CartItem[]
	addToCart: (item: CartItem) => void
	getCartCount: () => number
	getCartTotal: () => number
	getCartSubTotal: () => number
	updateItemQuantity: (item: CartItem, quantity: number) => void
	removeItemFromCart: (item: CartItem) => void
	calculateCartShipping: () => number
	isCartConflict: () => boolean
	determineMajorityCartRegion: () => 'ladoe' | 'standard' | 'teks' | undefined
	clearCart: () => void
	addQuoteItemsToCart: (items: CartItem[]) => void
}

export const CartContext = createContext<CartContext>({
	cart: [],
	addToCart: () => null,
	getCartCount: () => 0,
	getCartTotal: () => 0,
	getCartSubTotal: () => 0,
	updateItemQuantity: () => null,
	removeItemFromCart: () => null,
	calculateCartShipping: () => 0,
	isCartConflict: () => false,
	determineMajorityCartRegion: () => undefined,
	clearCart: () => null,
	addQuoteItemsToCart: () => null
})

interface Props {
	children: any
}

export const CartProvider = ({ children }: Props) => {
	const [cart, setCart] = useState<CartItem[]>([])

	// Adds the item to the cart
	function addToCart(item: CartItem): void {
		if (item.quantity === 0) {
			removeItemFromCart(item)
		} else {
			// Check if item is already in cart | returns -1 if it is not
			const index = cart.findIndex((cartItem: CartItem) => cartItem.id === item.id)
			if (index > -1) {
				// Duplicate the cart, find the item based on the index and set the quantity, then set the new cart as the cart
				const newCart = [...cart]
				newCart[index].quantity = item.quantity
				setCart(newCart)
			} else setCart((prevCart) => [...prevCart, item])
		}
	}

	// Updates the quantity of the item in the cart and removes it from the cart if quantity is zero
	function updateItemQuantity(item: CartItem, quantity: number): void {
		if (quantity === 0) {
			removeItemFromCart(item)
		} else {
			const index = cart.findIndex((cartItem: CartItem) => cartItem.id === item.id)
			const newCart = [...cart]
			newCart[index].quantity = quantity
			setCart(newCart)
		}
	}

	// Remove the item from the cart
	function removeItemFromCart(item: CartItem): void {
		if (cart.length === 1) {
			clearCart()
		} else {
			const index = cart.findIndex((cartItem: CartItem) => cartItem.id === item.id)
			const newCart = [...cart]
			newCart.splice(index, 1)
			setCart(newCart)
		}
	}

	// Returns the total number of items in the cart
	function getCartCount(): number {
		if (cart.length === 0) return 0
		else {
			const count = cart.reduce((a, c: any) => {
				return a + c.quantity
			}, 0)
			return count
		}
	}

	// Returns the subtotal of the cart
	function getCartSubTotal(): number {
		if (cart.length === 0) return 0
		else {
			const total = cart.reduce((a, c: any) => {
				return a + c.quantity * c.price
			}, 0)
			return total
		}
	}

	// Returns the calculated value of shipping if order is less than $200
	function calculateCartShipping(): number {
		const items = cart.reduce((a: any, c: any) => {
			return a + c.quantity * c.price
		}, 0)
		if (items >= 200) return 0
		else {
			const shippingCost = getCartSubTotal() * 0.1
			return Math.round(shippingCost * 100) / 100
		}
	}

	// Returns the total of the cart including shipping
	function getCartTotal(): number {
		if (cart.length === 0) return 0
		else {
			const items = cart.reduce((a: any, c: any) => {
				return a + c.quantity * c.price
			}, 0)
			const shipping = calculateCartShipping()
			const total = items + shipping
			return total
		}
	}

	// Checks that all items in the cart have the same productRegion
	function isCartConflict(): boolean {
		let checkItems: CartItem['region'][] = []
		cart.forEach((item: CartItem) => checkItems.push(item.region))
		return checkItems.every((item: any, index: number, items) => item === items[0])
	}

	function determineMajorityCartRegion() {
		let checkItems: CartItem['region'][] = []
		cart.forEach((item) => checkItems.push(item.region))
		const majorityRegionCode = checkItems
			.sort((a, b) => checkItems.filter((v) => v === a).length - checkItems.filter((v) => v === b).length)
			.pop()
		return majorityRegionCode
	}

	// Clears out the cart contents
	function clearCart(): void {
		setCart([])
	}

	// Takes the contents of the quote and adds it to the cart
	function addQuoteItemsToCart(items: CartItem[]): void {
		setCart(items)
	}

	// Add the contents of the cart to local storage
	useEffect(() => {
		setTimeout(() => {
			localStorage.setItem('cart', JSON.stringify(cart))
		}, 250)
	}, [cart])

	// Gets the cart from local storage and sets it in state
	useEffect(() => {
		if (localStorage.getItem('cart')) {
			setCart(JSON.parse(localStorage.getItem('cart') || ''))
		}
	}, [])

	return (
		<CartContext.Provider
			value={{
				cart,
				addToCart,
				getCartCount,
				getCartTotal,
				getCartSubTotal,
				updateItemQuantity,
				removeItemFromCart,
				calculateCartShipping,
				isCartConflict,
				determineMajorityCartRegion,
				clearCart,
				addQuoteItemsToCart
			}}
		>
			{children}
		</CartContext.Provider>
	)
}
