var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.25.2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
const packageJson = require('./package.json')

const SENTRY_DSN: string = process.env.NEXT_PUBLIC_SENTRY_DSN as string

if (process.env.NEXT_PUBLIC_ACTIVE_ENV === 'production') {
	Sentry.init({
		dsn: SENTRY_DSN,
		tracesSampleRate: 0.25,
		release: packageJson.version,
		replaysSessionSampleRate: 0.25,
		replaysOnErrorSampleRate: 1.0,

		integrations: [new Sentry.Replay({ maskAllText: false })]
	})
}
