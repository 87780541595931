import '../styles/tailwinds.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'instantsearch.css/themes/satellite.css'
import '@algolia/autocomplete-theme-classic'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import { ApolloProvider } from '@apollo/client'
import { CartProvider } from '../context/Cart'
import { PreferencesProvider } from '../context/Preferences'
import client from '../clients/apollo-client'
import { AuthProvider } from '../context/Auth'
import { ProfileProvider } from '../context/Profile'
import { AccountProvider } from '../context/Account'
import { AccountManagerProvider } from '../context/AccountManager'
import { LouisianaProvider } from '../context/Louisiana'
import { QuoteProvider } from 'context/Quote'
import { QueryClientProvider } from 'react-query'
import queryClient from 'clients/react-query-client'
import { ReactQueryDevtools } from 'react-query/devtools'
import { SetupProvider } from 'context/Setup'
import LogRocket from 'logrocket'
import { releaseVersion } from '../constants'

process.env.NEXT_PUBLIC_ACTIVE_ENV === 'production' && LogRocket.init('zpy02v/arc-quotes', { release: releaseVersion })

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<>
			{process.env.NEXT_PUBLIC_ACTIVE_ENV === 'production' && (
				<>
					<Script
						strategy="afterInteractive"
						dangerouslySetInnerHTML={{
							__html: `
							(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
							new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
							j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
							'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
							})(window,document,'script','dataLayer','GTM-NZSV4H4');
							`
						}}
					/>
				</>
			)}

			<AuthProvider>
				<ProfileProvider>
					<SetupProvider>
						<AccountProvider>
							<AccountManagerProvider>
								<PreferencesProvider>
									<LouisianaProvider>
										<QueryClientProvider client={queryClient}>
											<ReactQueryDevtools initialIsOpen={false} />
											<ApolloProvider client={client}>
												<QuoteProvider>
													<CartProvider>
														<Component {...pageProps} />
													</CartProvider>
												</QuoteProvider>
											</ApolloProvider>
										</QueryClientProvider>
									</LouisianaProvider>
								</PreferencesProvider>
							</AccountManagerProvider>
						</AccountProvider>
					</SetupProvider>
				</ProfileProvider>
			</AuthProvider>
		</>
	)
}
export default MyApp
