import { createContext, useState, useEffect, useContext } from 'react'
import { SalesforceAccount } from 'types/common-types'
import axios, { AxiosResponse } from 'axios'
import { ProfileContext } from './Profile'
import { api } from 'clients/axios'

interface AccountContext {
	account: SalesforceAccount | null
	childAccounts: SalesforceAccount[]
	accountLoading: boolean
	accountError: string
}

export const AccountContext = createContext<AccountContext>({
	account: null,
	childAccounts: [],
	accountLoading: true,
	accountError: ''
})

interface Props {
	children: any
}

export const AccountProvider = ({ children }: Props) => {
	const { profile } = useContext(ProfileContext)

	const [account, setAccount] = useState<SalesforceAccount | null>(null)
	const [childAccounts, setChildAccounts] = useState<SalesforceAccount[]>([])
	const [accountLoading, setAccountLoading] = useState(true)
	const [accountError, setAccountError] = useState('')

	useEffect(() => {
		async function getAccount(id: string) {
			try {
				const account: AxiosResponse<SalesforceAccount> = await api.get(`/api/v1/salesforce/account/${id}`)
				if (account.data) {
					setAccount(account.data)
					setAccountLoading(false)
				} else {
					setAccountError('Could not locate Account')
					setAccountLoading(false)
				}
				const childAccounts: AxiosResponse<SalesforceAccount[]> = await api.get(
					`/api/v1/salesforce/account/children/${id}`
				)
				setChildAccounts(childAccounts.data)
			} catch (error: any) {
				if (axios.isAxiosError(error)) {
					console.log(error.response?.data)
				} else {
					console.log(error.message)
				}
			}
		}
		if (profile?.salesforceAccountId) getAccount(profile.salesforceAccountId)
	}, [profile?.salesforceAccountId])

	return (
		<AccountContext.Provider value={{ account, childAccounts, accountLoading, accountError }}>
			{children}
		</AccountContext.Provider>
	)
}
