import { createContext, useState, useEffect, useContext } from 'react'
import { ProfileContext } from './Profile'
import { SalesTeam, SalesTeamMember } from 'types/common-types'
import { api } from 'clients/axios'
import { AxiosResponse } from 'axios'

interface AccountManagerContext {
	accountManager: SalesTeamMember | null
	accountManagerLoading: boolean
	accountManagerError: string
}

export const AccountManagerContext = createContext<AccountManagerContext>({
	accountManager: null,
	accountManagerLoading: true,
	accountManagerError: ''
})

interface Props {
	children: any
}

export const AccountManagerProvider = ({ children }: Props) => {
	const { profile } = useContext(ProfileContext)

	const [accountManager, setAccountManager] = useState<SalesTeamMember | null>(null)
	const [accountManagerLoading, setAccountManagerLoading] = useState(true)
	const [accountManagerError, setAccountManagerError] = useState('')

	useEffect(() => {
		async function getAccountManagerByAccountId(id: string) {
			const accountManager: AxiosResponse<SalesTeamMember> = await api.get(
				`/api/v1/salesforce/account-manager/account/${id}`
			)
			if (accountManager.data) {
				setAccountManager(accountManager.data)
				setAccountManagerLoading(false)
			} else {
				setAccountManagerError('Could not locate Account')
				setAccountManagerLoading(false)
			}
		}
		async function getAccountManagerByZipCode(zipCode: string) {
			const accountManager: AxiosResponse<SalesTeamMember> = await api.get(
				`/api/v1/salesforce/account-manager/zip-code/${zipCode}`
			)
			if (accountManager.data) {
				setAccountManager(accountManager.data)
				setAccountManagerLoading(false)
			} else {
				setAccountManagerError('Could not locate Account')
				setAccountManagerLoading(false)
			}
		}
		if (profile?.cantLocateZipCode) getAccountManagerByZipCode(profile.cantLocateZipCode)
		else if (profile?.salesforceAccountId) getAccountManagerByAccountId(profile.salesforceAccountId)
	}, [profile?.salesforceAccountId, profile?.cantLocateZipCode])

	return (
		<AccountManagerContext.Provider value={{ accountManager, accountManagerLoading, accountManagerError }}>
			{children}
		</AccountManagerContext.Provider>
	)
}
